import React from 'react'
import { Paper } from '@mui/material'

import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import { Box } from '@mui/material'
import Markdown from 'markdown-to-jsx';

import { styled } from '@mui/material/styles';

const PREFIX = 'SmallHero';
const classes = {
  root: `${PREFIX}-root`,
  section: `${PREFIX}-section`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  overlay: `${PREFIX}-overlay`,
  image: `${PREFIX}-image`,
  detailButton: `${PREFIX}-detailButton`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.section}`]: {
    height: "40vw",
    maxHeight: "400px",
    minHeight: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0 0 30px 0 rgb(0 0 0 / 3%)",
    position: "relative",
    borderBottom: "8px solid #253f72",
    borderRadius: "0",
    overflow: "hidden",
  },
  [`& .${classes.container}`]: {
    height: "100%",
    overflow: "hidden",

  },
  [`& .${classes.content}`]: {
    height: "100%",
    color: "white",
    zIndex: 3,
    position: "relative",

  },
  [`& .${classes.overlay}`]: {
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 2,
  },  
  [`& .${classes.image}`]: {
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 1,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    filter: "blur(2px)",
    transform: "scale(1.1)"
  },  
  [`& .${classes.detailButton}`]: {
    fontWeight: "bold",
  },  
}))

export default function SmallHero({title, description, image, buttonCaption, buttonIcon, href, hideButton}) {
    return (
        <Root className={classes.root}>
            <Paper className={classes.section}>
                <div className={classes.image} style={{backgroundImage: "url(" + image + ")"}} />
                <div className={classes.overlay}></div>
                <Container className={classes.container} maxWidth='md'>
                    <Grid className={classes.content} container alignItems='center' justifyContent='space-between'>
                        <Grid item>
                            <Typography component='h1' variant='h2'>{title}</Typography>
                            <Markdown options={{ wrapper: 'article' }} style={{ display: "inline-block", fontSize: "1.3rem"}}>
                                    {description}
                            </Markdown>
                            <Box my={3} hidden={hideButton || false}>
                                <Button href={href} variant="outlined" color="secondary" className={classes.detailButton} endIcon={buttonIcon}>{buttonCaption}</Button> 
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Root>
    )
}

