import * as React from "react"
import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"

import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import lightTheme from "../themes/light"
import HomeTextContainer from "../components/HomeTextContainer"
import SmallHero from "../components/SmallHero"

import { graphql } from "gatsby"

import AboutImg from "../images/about.png"

const AboutPage = ({data}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS - Chi siamo</title>
      <CssBaseline />
      <TopBar  />
      <SmallHero 
        title="Chi siamo?"
        description="ITS è un'azienda di telecomunicazioni scelta negli anni da più di <strong>trecento clienti</strong> per la realizzazione di impianti di telecomunicazioni, videosorveglianza e sistemi antintrusione – anche a <strong> noleggio </strong>."
        image={AboutImg}
        hideButton
        />

        <HomeTextContainer 
        title="ITS Telecomunicazioni //" 
        subtitle="About" 
        hideIcon
        hideButton
        description={ data.markdownRemark.html }
        noBorder/>

      <Footer />
    </ThemeProvider>
  )
}

export default AboutPage

export const contentQuery = graphql`
{
  markdownRemark(fileAbsolutePath: { regex: "/about.md/"}) {
    frontmatter {
			title
    }
    html
  }
}
`