import React from 'react'

import { Container, Paper } from '@mui/material'
import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Button } from '@mui/material'
import { Box } from '@mui/material'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import Markdown from 'markdown-to-jsx';

import { styled } from '@mui/material/styles';

const PREFIX = 'HomeTextContainer';
const classes = {
  root: `${PREFIX}-root`,
  roundImg: `${PREFIX}-roundImg`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: "#f7f7f8",
    // boxShadow: "0 0 30px 0 rgb(0 0 0 / 3%)",
    //borderBottom: "1px solid #ebebeb"
  },

  [`& .${classes.roundImg}`]: {
        // borderRadius: "25%",
        // minWidth: "80px",
        width: "calc(100%)",
        // border: "2px solid",
        // width: "100%",
        color: theme.palette.secondary.main,
        // padding: "10%",
        // boxShadow: theme.shadows[2],
        margin: "auto",
        filter: "drop-shadow(-2px -2px 0px #e91e63) drop-shadow(2px 2px 0 cyan)",
        opacity: "0.87"
  },  
}))


export default function HomeTextContainer( {title, subtitle, description, icon, hideIcon, pt, pb, direction, hideButton, hrefBtn, noBorder} ) {
    return (
        <Root className={classes.root} >
            <Container maxWidth='md' >
                <Box paddingTop={pt || 5} paddingBottom={pb || 0} marginBottom={ hideButton?5:0 } >
                    <Paper  style={{position:'relative'}} elevation={0} sx={{paddingTop: 5, paddingBottom: 2, paddingX: 5}} >
                        <Grid container spacing={3} justifyContent="center" alignItems="top" direction={direction || "row"} >
                            <Grid item md={2} sm={2} lg={2} xs={3} hidden={hideIcon || false} >
                                <img className={classes.roundImg} src={icon} alt={icon} />
                            </Grid>
                            <Grid item xs={ hideIcon?12:12 } sm={ hideIcon?12:10 } lg={ hideIcon?12:10 } md={ hideIcon?12:10 }>
                                <Box>
                                    <Typography style={{ fontWeight: 600 }} variant="overline" color="primary">{ title }</Typography>
                                    <Typography variant="overline" color="secondary"> { subtitle }</Typography> 
                                </Box>
                                <Markdown options={{ wrapper: 'article' }} style={{ display: "inline-block", fontSize: "1.25rem"}}>
                                    {description}
                                </Markdown>                            
                                <Box marginY={3}  hidden={hideButton || false} textAlign="right"> 
                                    <Button href={hrefBtn || "#"} color="primary" variant="outlined" style={{fontWeight: "600"}} endIcon={<ArrowForwardIcon />}> scopri di più </Button> 
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Container>
        </Root>
    )
}

